@import "../../../styles/_colors";

#search-container {
  display: flex;
  align-items: center;
  justify-content: right;
  grid-area: search-container;
  background: $primary-color;
  padding: 0 20px;
  // border-radius: 10px 0 0 0;
  box-shadow: 0 1px 3px -1px $shadow-box-color;
  z-index: 1;
  height: 10%;

  input {
    width: 0;
    flex: 1 0 0;
    color: $accent-color;
    outline: none;
    font-weight: bold;
    border-radius: 2px;
    height: 30px;
    border: 0;
    padding-left: 48px;
    padding-right: 20px;
    font-size: 1rem;
    background: url("../../../images/search/search.svg") no-repeat
      $search-background-image-color;
    background-position: 15px center;
    background-size: 20px 20px;
  }

  input::placeholder {
    color: $light-grey-border;
    font-weight: bold;
  }
}
