.back {
  background-color: rgb(223, 224, 223);
  height: 100vh;
  display: flex;
  justify-content: center;
  text-align: center;
}

.imgGrid {
  width: 100%;
  padding-top: 15%;
}

img {
  width: 20%;
}

.send-button {
  color: white !important;
  background-color: #064987 !important;
  width: 70%;
}
