@import "../../../styles/_colors";

#no-coversation-layout {
  flex: 1 0 0%;
  display: flex;
  flex-direction: column;

  #no-conversation-content {
    flex: 1 0 0%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 0 0 10px 10px;
  }

  h2 {
    color: $primary-color;
    font-size: 2rem;
    margin-bottom: 2rem;
  }

  p {
    font-size: 2rem;
  }

  p:last-of-type {
    margin-bottom: 1.5rem;
  }
}
