@import "../../styles/_colors";

$message-time-color: darken($accent-color, 46.5%);
$speech-bubble-border-color: darken($accent-color, 13.18);

audio::-webkit-media-controls-panel {
  background-color: #eee;
  border: 1px solid #cccccc;
}

audio::-webkit-media-controls-mute-button {
  background-color: #b1d4e0;
  border-radius: 50%;
}

.message-row {
  display: grid;
  grid-template-columns: 45%;
  margin-bottom: 20px;

  > .message-content {
    display: grid;
    width: 100%;

    > img {
      border-radius: 100%;
      grid-row: span 2;
      width: 100%;
      height: 48px;
    }

    > .message-time {
      font-size: 0.8rem;
      color: $message-time-color;
      display: flex;
      align-items: center;
    }

    > .message-text {
      // padding: 9px 14px;
      font-size: 1rem;
      margin-bottom: 5px;
      width: 60%;
    }
  }

  &.you-message {
    justify-content: end;

    > .message-content {
      justify-items: end;

      > .message-text {
        background: $primary-color;
        color: $contrasted-primary-text;
        border: 1px solid $primary-color;
        border-radius: 14px 14px 0 14px;
      }
    }
  }

  &.other-message {
    justify-items: start;

    > .message-content {
      grid-template-columns: 48px 1fr;
      grid-column-gap: 15px;

      > .message-text {
        background: $accent-color;
        color: $contrasted-accent-text;
        border: 1px solid $speech-bubble-border-color;
        border-radius: 14px 14px 14px 0;
      }
      > .message-media {
        background: $accent-color;
        color: $contrasted-accent-text;
        border-radius: 14px 14px 14px 0;
      }
    }
  }

  &.info-message {
    display: flex;
    justify-content: center;
    color: #064987;
  }

  &.feedback-message {
    display: flex;
    justify-content: center;

    > .feedback {
      background-color: #eee;
      width: 50%;
      padding: 2% 3% 2% 3%;
      color: black;
      font-weight: bold;
      border-radius: 7.5px;
      text-align: center;

      > .feedback-text {
        margin-bottom: 10px;
      }

      > .css-1oetach-MuiRating-root.Mui-disabled {
        color: #faaf00;
        opacity: unset;
      }
    }
  }
}
