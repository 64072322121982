#chat-message-list {
  grid-area: chat-message-list;
  display: flex;
  flex-direction: column-reverse;
  padding: 0 20px;
  overflow-y: overlay;
  flex: 1;
  height: 100%;
  width: -webkit-fill-available;
}
