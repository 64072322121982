@import "../../../styles/_colors";

.conversation {
  display: grid;
  grid-template-columns: 40px 1fr max-content;
  grid-gap: 10px;
  color: $contrasted-primary-text; // $contrasting-primary-text-color;
  font-size: 1rem;
  border-bottom: 1px solid $primary-dark;
  padding: 20px 20px 20px 15px;

  &.active,
  &:hover {
    background: $primary-dark;
  }

  &:hover {
    cursor: pointer;
  }

  > img {
    grid-row: span 2;
    height: 40px;
    width: 40px;
    border-radius: 100%;
  }

  .title-text {
    font-weight: bold;
    padding-left: 5px;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }

  .created-date {
    color: $light-grey-border;
    white-space: nowrap;
    font-size: 0.8rem;
  }

  .conversation-message {
    grid-column: span 2;
    padding-left: 5px;
    width: 100%;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }

  .conversation-issue {
    grid-column: span 1;
    padding-left: 5px;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }

  .conversation-number {
    grid-column: span 1;
    background-color: #0c8b1b;
    border-radius: 60%;
    float: right;
    width: 20px;
    height: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
