@import "../../styles/_colors";

$chat-shell-background: lighten($accent-color, 65%);

#chat-container {
  display: flex;
  grid:
    "search-container chat-title" 71px
    "conversation-list chat-message-list" 1fr
    "new-message-container chat-form" 78px
    / 275px 1fr;
  // min-width: 1000px;

  // max-width: 1000px;
  // max-height: 800px;
  width: 100%;
  height: 90vh;
  background: $chat-shell-background;
  // border-radius: 10px;
}

#chat-container1 {
  display: flex;
  // grid:
  //   "search-container chat-title" 71px
  //   "conversation-list chat-message-list" 1fr
  //   "new-message-container chat-form" 78px/280px 1fr;
  height: 90vh;
  width: 30%;

  /* Misc */
  align-items: center;
  // display: flex;
  justify-content: center;
  // transform: translate(100%, 50);
  // animation-delay: 0s; /* instead of 1 sec */
  // animation-play-state: running;
  // animation-iteration-count: 1;
  // animation-duration: 1s; /* instead of 5 sec */
  // animation-name: supportanimation;
}

@keyframes supportanimation {
  from {
    transform: translate(200%, 0);
    opacity: 0;
  }
  to {
    transform: translate(0%, 0);
    opacity: 1;
  }
}

#chat-container11 {
  // display: grid;
  // grid:
  //   "search-container chat-title" 71px
  //   "conversation-list chat-message-list" 1fr
  //   "new-message-container chat-form" 78px/280px 1fr;
  height: 100%;
  width: 100%;

  /* Misc */
  // align-items: center;
  // display: flex;
  // justify-content: center;
}
.resizer[data-direction="horizontal"] {
  background-color: #cbd5e0;
  cursor: ew-resize;
  height: 100%;
  width: 2px;
}

#chat-container2 {
  display: flex;
  // grid:
  //   "search-container chat-title" 71px
  //   "conversation-list chat-message-list" 1fr
  //   "new-message-container chat-form" 78px/0px 1fr;
  width: 100%;
  height: 90vh;
  flex: 1;

  /* Misc */
  align-items: center;
  // display: flex;
  flex-direction: column;
  justify-content: center;
}
